// We need to load the Saffron SCSS styles from here in order to
// prevent icon flicker in Olympus
//
// The index.ts file isn't exposed to the MFEs.
// We need to load the Saffron CSS from here in order to:
// 1. Prevent duplicate copies of the Saffron CSS when loading from different
//    MFEs in Olympus
// 2. Provide the saffron CSS when developing the MFE standalone.
import './saffron.scss';

import('./bootstrap');
